import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import IndustryHeroBg from "../components/Image/IndustryHeroBg"
import {
  HeroWrap,
  BreadCrumb,
  Background,
  TwoGridWrap,
  LeftWrap,
  RightWrap,
  MarkdownContent,
  SectionPageTitle,
} from "../components/SectionTags"
import AddressCTA from "../components/CallToAction/AddressCTA"

import ServiceContact from "../sections/service/ServiceContact"
import ArrowIcon from "../components/Icons/ArrowIcon"

const RightWraps = styled(RightWrap)`
  .gatsby-image-wrapper{
    border: 2px solid #000;
  }
`

const VerticalTitle = styled.strong`
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  margin: 0;
  position: absolute;
  width: ${(props) => props.width};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  transform: rotate(-90deg) translateY(-50%);
  color: #333333;
  opacity: 0.1;
  z-index: 3;
  font-size: 44px;
  line-height: 58px;
  @media (min-width: 1600px) {
    font-size: 50px;
    line-height: 66px;
  }
  @media (max-width: 1440px) {
    transform: none;
    top: ${(props) => props.mTop};
    left: ${(props) => props.mLeft};
  }
`
const SliderWrap = styled.div`
  display: none;
  .slick-arrow {
    display: none !important;
  }
  @media (max-width: 991px) {
    display: block;
    .slick-list {
      height: 100%;
      padding-top: 80px;
    }
    .slick-track {
      height: 180px;
    }
    .slick-initialized .slick-slide {
      padding-right: 10px;
    }
  }
`
const Year = styled.strong`
  color: ${(props) => props.color};
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 30px;
  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
`
const Description = styled.p`
  font-size: 16px;
  line-height: 28px;
  padding-right: 30px;
  opacity: ${(props) => props.opacity};
  color: ${(props) => props.color};
  
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
`
const YearWrap = styled.div`
  margin-top: 100px;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  height: 200px;
  @media (max-width: 991px) {
    display: none;
  }
`
const YearsGroup = styled.div`
  position: relative;
`
const OneYear = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  width: ${(props) => props.width};
  margin: auto;
  background: ${(props) => props.bg};
  padding: 20px;
  z-index: ${(props) => props.zIndex};
  border: 1px solid #dedede;
  border-radius: 8px;
  cursor: pointer;
  & h3 {
    font-size: ${(props) => props.fSize};
    margin-top: ${(props) => props.mTop};
    & svg {
      display: ${(props) => props.display};
      margin-top: 10px;
      float: right;
    }
  }
`
const GroupPlusIcon = styled.span`
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  color: #000000;
  display: ${(props) => props.display};
  width: 30px;
  height: 30px;
  text-align: center;
`
const GroupMinusIcon = styled.span`
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  color: #ffffff;
  display: ${(props) => props.display};
  width: 30px;
  height: 30px;
  text-align: center;
`
class TimeLinePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTimeline: this.props.data.allContentfulTimeline.edges[0].node,
      currentGroup: 1,
    }

    this.onClickTimeline = this.onClickTimeline.bind(this)
  }
  onClickTimeline(data, i) {
    this.setState({
      currentTimeline: data.node,
      currentGroup: i,
    })
  }
  onChangeGroup(data) {
    this.setState({
      currentGroup: data,
    })
  }
  render() {
    const pageData = this.props.data.contentfulTimelinePage
    const timelineData = this.props.data.allContentfulTimeline
    const ContactDat = pageData.contactForm
    const AddressData = pageData.addressCta
    const { currentTimeline, currentGroup } = this.state
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          },
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1
          },
        },
      ],  

    }
    var years = timelineData.edges.length
    var group = Math.ceil(years / 4)
    return (
      <Layout>
        <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}        
        />        
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <HeroWrap bg="#F5F5F5">
          <div className="container">
            <BreadCrumb>
              <Link to="/">
                <span>Home</span>
              </Link>
              <Link to="/about">
                <span>About AES</span>
              </Link>
              {pageData.heroTitle}
            </BreadCrumb>
            <TwoGridWrap>
              <LeftWrap>
                <VerticalTitle
                  top="50%"
                  width="225px"
                  left="-155px"
                  mTop="0"
                  mLeft="0"
                >
                  {pageData.heroTitle}
                </VerticalTitle>
                <SectionPageTitle mb="20px">
                  {currentTimeline.year}
                </SectionPageTitle>
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html:
                      currentTimeline.description.childMarkdownRemark.html,
                  }}
                />
              </LeftWrap>
              <RightWraps>
                <GatsbyImage image={getImage(currentTimeline.image)} alt="AES" />
              </RightWraps>
            </TwoGridWrap>
          </div>
          <Background bg="#F5F5F5">
            <IndustryHeroBg />
          </Background>
          <div className="container">
            <VerticalTitle
              top="130px"
              width="130px"
              left="-90px"
              mTop="-5px"
              mLeft="20px"
            >
              {pageData.sliderTitle}
            </VerticalTitle>
          </div>

          <div className="container">
            <YearWrap>
              <YearsGroup>
                {timelineData.edges.map((item, i) => {
                  if (i < group) {
                    return (
                      <OneYear
                        bg={
                          item.node.year === currentTimeline.year
                            ? "#0059B2"
                            : "white"
                        }
                        zIndex={
                          item.node.year === currentTimeline.year
                            ? "50"
                            : `${group - i}`
                        }
                        left={`${i * 4}%`}
                        width={`${100 - i * 8}%`}
                        top={
                          currentGroup === 1 ? `-${i * 30}px` : `-${i * 15}px`
                        }
                        fSize={
                          currentGroup === 1 &&
                          item.node.year !== currentTimeline.year
                            ? "20px"
                            : "24px"
                        }
                        mTop={
                          currentGroup === 1 &&
                          item.node.year !== currentTimeline.year
                            ? "-20px"
                            : "0"
                        }
                        display={
                          currentGroup === 1 &&
                          item.node.year !== currentTimeline.year
                            ? "inline-block"
                            : "none"
                        }
                        key={i}
                        onClick={() => this.onClickTimeline(item, 1)}
                      >
                        <Year
                          color={
                            item.node.year === currentTimeline.year
                              ? "white"
                              : "#000000"
                          }
                        >
                          {item.node.year}
                          <ArrowIcon
                            fill={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "#FAB702"
                            }
                          />
                        </Year>
                        <Description
                          opacity={
                            item.node.year === currentTimeline.year
                              ? "1"
                              : "0.5"
                          }
                          color={
                            item.node.year === currentTimeline.year
                              ? "white"
                              : "#333333"
                          }
                        >
                          {item.node.label}
                        </Description>
                        <GroupPlusIcon
                          display={currentGroup === 1 ? "none" : "block"}
                        >
                          {" "}
                          +{" "}
                        </GroupPlusIcon>
                        <GroupMinusIcon
                          display={currentGroup === 1 ? "block" : "none"}
                        >
                          {" "}
                          -{" "}
                        </GroupMinusIcon>
                      </OneYear>
                    )
                  }
                  return <></>
                })}
              </YearsGroup>
              <YearsGroup>
                {timelineData.edges.map((item, i) => {
                  if (i >= group && i < group * 2) {
                    return (
                      <OneYear
                        bg={
                          item.node.year === currentTimeline.year
                            ? "#0059B2"
                            : "white"
                        }
                        zIndex={
                          item.node.year === currentTimeline.year
                            ? "50"
                            : `${2 * group - i}`
                        }
                        left={`${(i - group) * 4}%`}
                        width={`${100 - (i - group) * 8}%`}
                        top={
                          currentGroup === 2
                            ? `-${(i - group) * 30}px`
                            : `-${(i - group) * 15}px`
                        }
                        fSize={
                          currentGroup === 2 &&
                          item.node.year !== currentTimeline.year
                            ? "20px"
                            : "24px"
                        }
                        mTop={
                          currentGroup === 2 &&
                          item.node.year !== currentTimeline.year
                            ? "-20px"
                            : "0"
                        }
                        display={
                          currentGroup === 2 &&
                          item.node.year !== currentTimeline.year
                            ? "inline-block"
                            : "none"
                        }
                        onClick={() => this.onClickTimeline(item, 2)}
                        key={i}
                      >
                        <Year
                          color={
                            item.node.year === currentTimeline.year
                              ? "white"
                              : "#000000"
                          }
                        >
                          {item.node.year}
                          <ArrowIcon
                            fill={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "white"
                            }
                          />
                        </Year>
                        <Description
                          opacity={
                            item.node.year === currentTimeline.year
                              ? "1"
                              : "0.5"
                          }
                          color={
                            item.node.year === currentTimeline.year
                              ? "white"
                              : "#333333"
                          }
                        >
                          {item.node.label}
                        </Description>
                        <GroupPlusIcon
                          display={currentGroup === 2 ? "none" : "block"}
                        >
                          {" "}
                          +{" "}
                        </GroupPlusIcon>
                        <GroupMinusIcon
                          display={currentGroup === 2 ? "block" : "none"}
                        >
                          {" "}
                          -{" "}
                        </GroupMinusIcon>
                      </OneYear>
                    )
                  }
                  return <></>
                })}
              </YearsGroup>
              <YearsGroup>
                {timelineData.edges.map((item, i) => {
                  if (i >= group * 2 && i < group * 3) {
                    return (
                      <OneYear
                        bg={
                          item.node.year === currentTimeline.year
                            ? "#0059B2"
                            : "white"
                        }
                        zIndex={
                          item.node.year === currentTimeline.year
                            ? "50"
                            : `${3 * group - i}`
                        }
                        left={`${(i - 2 * group) * 4}%`}
                        width={`${100 - (i - 2 * group) * 8}%`}
                        top={
                          currentGroup === 3
                            ? `-${(i - group * 2) * 30}px`
                            : `-${(i - group * 2) * 15}px`
                        }
                        fSize={
                          currentGroup === 3 &&
                          item.node.year !== currentTimeline.year
                            ? "20px"
                            : "24px"
                        }
                        mTop={
                          currentGroup === 3 &&
                          item.node.year !== currentTimeline.year
                            ? "-20px"
                            : "0"
                        }
                        display={
                          currentGroup === 3 &&
                          item.node.year !== currentTimeline.year
                            ? "inline-block"
                            : "none"
                        }
                        key={i}
                        onClick={() => this.onClickTimeline(item, 3)}
                      >
                        <Year
                          color={
                            item.node.year === currentTimeline.year
                              ? "white"
                              : "#000000"
                          }
                        >
                          {item.node.year}
                          <ArrowIcon
                            fill={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "white"
                            }
                          />
                        </Year>
                        <Description
                          opacity={
                            item.node.year === currentTimeline.year
                              ? "1"
                              : "0.5"
                          }
                          color={
                            item.node.year === currentTimeline.year
                              ? "white"
                              : "#333333"
                          }
                        >
                          {item.node.label}
                        </Description>
                        <GroupPlusIcon
                          display={currentGroup === 3 ? "none" : "block"}
                        >
                          {" "}
                          +{" "}
                        </GroupPlusIcon>
                        <GroupMinusIcon
                          display={currentGroup === 3 ? "block" : "none"}
                        >
                          {" "}
                          -{" "}
                        </GroupMinusIcon>
                      </OneYear>
                    )
                  }
                  return <></>
                })}
              </YearsGroup>
              <YearsGroup>
                {timelineData.edges.map((item, i) => {
                  if (i >= group * 3) {
                    return (
                      <OneYear
                        bg={
                          item.node.year === currentTimeline.year
                            ? "#0059B2"
                            : "white"
                        }
                        zIndex={
                          item.node.year === currentTimeline.year
                            ? "50"
                            : `${4 * group - i}`
                        }
                        left={`${(i - 3 * group) * 4}%`}
                        width={`${100 - (i - 3 * group) * 8}%`}
                        top={
                          currentGroup === 4
                            ? `-${(i - group * 3) * 30}px`
                            : `-${(i - group * 3) * 15}px`
                        }
                        fSize={
                          currentGroup === 4 &&
                          item.node.year !== currentTimeline.year
                            ? "20px"
                            : "24px"
                        }
                        mTop={
                          currentGroup === 4 &&
                          item.node.year !== currentTimeline.year
                            ? "-20px"
                            : "0"
                        }
                        display={
                          currentGroup === 4 &&
                          item.node.year !== currentTimeline.year
                            ? "inline-block"
                            : "none"
                        }
                        onClick={() => this.onClickTimeline(item, 4)}
                      >
                        <Year
                          color={
                            item.node.year === currentTimeline.year
                              ? "white"
                              : "#000000"
                          }
                        >
                          {item.node.year}
                          <ArrowIcon
                            fill={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "white"
                            }
                          />
                        </Year>
                        <Description
                          opacity={
                            item.node.year === currentTimeline.year
                              ? "1"
                              : "0.5"
                          }
                          color={
                            item.node.year === currentTimeline.year
                              ? "white"
                              : "#333333"
                          }
                        >
                          {item.node.label}
                        </Description>
                        <GroupPlusIcon
                          display={currentGroup === 4 ? "none" : "block"}
                        >
                          {" "}
                          +{" "}
                        </GroupPlusIcon>
                        <GroupMinusIcon
                          display={currentGroup === 4 ? "block" : "none"}
                        >
                          {" "}
                          -{" "}
                        </GroupMinusIcon>
                      </OneYear>
                    )
                  }
                  return <></>
                })}
              </YearsGroup>
            </YearWrap>
          </div>
          <div className="container">
            <SliderWrap>
              <Slider {...settings}>
                <YearsGroup>
                  {timelineData.edges.map((item, i) => {
                    if (i < group) {
                      return (
                        <OneYear
                          bg={
                            item.node.year === currentTimeline.year
                              ? "#0059B2"
                              : "white"
                          }
                          zIndex={
                            item.node.year === currentTimeline.year
                              ? "50"
                              : `${group - i}`
                          }
                          left={`${i * 4}%`}
                          width={`${100 - i * 8}%`}
                          top={
                            currentGroup === 1 ? `-${i * 30}px` : `-${i * 15}px`
                          }
                          fSize={
                            currentGroup === 1 &&
                            item.node.year !== currentTimeline.year
                              ? "20px"
                              : "24px"
                          }
                          mTop={
                            currentGroup === 1 &&
                            item.node.year !== currentTimeline.year
                              ? "-20px"
                              : "0"
                          }
                          display={
                            currentGroup === 1 &&
                            item.node.year !== currentTimeline.year
                              ? "inline-block"
                              : "none"
                          }
                          key={i}
                          onClick={() => this.onClickTimeline(item, 1)}
                        >
                          <Year
                            color={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "#000000"
                            }
                          >
                            {item.node.year}
                            <ArrowIcon
                              fill={
                                item.node.year === currentTimeline.year
                                  ? "white"
                                  : "#FAB702"
                              }
                            />
                          </Year>
                          <Description
                            opacity={
                              item.node.year === currentTimeline.year
                                ? "1"
                                : "0.5"
                            }
                            color={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "#333333"
                            }
                          >
                            {item.node.label}
                          </Description>
                          <GroupPlusIcon
                            display={currentGroup === 1 ? "none" : "block"}
                          >
                            {" "}
                            +{" "}
                          </GroupPlusIcon>
                          <GroupMinusIcon
                            display={currentGroup === 1 ? "block" : "none"}
                          >
                            {" "}
                            -{" "}
                          </GroupMinusIcon>
                        </OneYear>
                      )
                    }
                    return <></>
                  })}
                </YearsGroup>
                <YearsGroup>
                  {timelineData.edges.map((item, i) => {
                    if (i >= group && i < group * 2) {
                      return (
                        <OneYear
                          bg={
                            item.node.year === currentTimeline.year
                              ? "#0059B2"
                              : "white"
                          }
                          zIndex={
                            item.node.year === currentTimeline.year
                              ? "50"
                              : `${2 * group - i}`
                          }
                          left={`${(i - group) * 4}%`}
                          width={`${100 - (i - group) * 8}%`}
                          top={
                            currentGroup === 2
                              ? `-${(i - group) * 30}px`
                              : `-${(i - group) * 15}px`
                          }
                          fSize={
                            currentGroup === 2 &&
                            item.node.year !== currentTimeline.year
                              ? "20px"
                              : "24px"
                          }
                          mTop={
                            currentGroup === 2 &&
                            item.node.year !== currentTimeline.year
                              ? "-20px"
                              : "0"
                          }
                          display={
                            currentGroup === 2 &&
                            item.node.year !== currentTimeline.year
                              ? "inline-block"
                              : "none"
                          }
                          onClick={() => this.onClickTimeline(item, 2)}
                          key={i}
                        >
                          <Year
                            color={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "#000000"
                            }
                          >
                            {item.node.year}
                            <ArrowIcon
                              fill={
                                item.node.year === currentTimeline.year
                                  ? "white"
                                  : "#FAB702"
                              }
                            />
                          </Year>
                          <Description
                            opacity={
                              item.node.year === currentTimeline.year
                                ? "1"
                                : "0.5"
                            }
                            color={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "#333333"
                            }
                          >
                            {item.node.label}
                          </Description>
                          <GroupPlusIcon
                            display={currentGroup === 2 ? "none" : "block"}
                          >
                            {" "}
                            +{" "}
                          </GroupPlusIcon>
                          <GroupMinusIcon
                            display={currentGroup === 2 ? "block" : "none"}
                          >
                            {" "}
                            -{" "}
                          </GroupMinusIcon>
                        </OneYear>
                      )
                    }
                    return <></>
                  })}
                </YearsGroup>
                <YearsGroup>
                  {timelineData.edges.map((item, i) => {
                    if (i >= group * 2 && i < group * 3) {
                      return (
                        <OneYear
                          bg={
                            item.node.year === currentTimeline.year
                              ? "#0059B2"
                              : "white"
                          }
                          zIndex={
                            item.node.year === currentTimeline.year
                              ? "50"
                              : `${3 * group - i}`
                          }
                          left={`${(i - 2 * group) * 4}%`}
                          width={`${100 - (i - 2 * group) * 8}%`}
                          top={
                            currentGroup === 3
                              ? `-${(i - group * 2) * 30}px`
                              : `-${(i - group * 2) * 15}px`
                          }
                          fSize={
                            currentGroup === 3 &&
                            item.node.year !== currentTimeline.year
                              ? "20px"
                              : "24px"
                          }
                          mTop={
                            currentGroup === 3 &&
                            item.node.year !== currentTimeline.year
                              ? "-20px"
                              : "0"
                          }
                          display={
                            currentGroup === 3 &&
                            item.node.year !== currentTimeline.year
                              ? "inline-block"
                              : "none"
                          }
                          key={i}
                          onClick={() => this.onClickTimeline(item, 3)}
                        >
                          <Year
                            color={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "#000000"
                            }
                          >
                            {item.node.year}
                            <ArrowIcon
                              fill={
                                item.node.year === currentTimeline.year
                                  ? "white"
                                  : "#FAB702"
                              }
                            />
                          </Year>
                          <Description
                            opacity={
                              item.node.year === currentTimeline.year
                                ? "1"
                                : "0.5"
                            }
                            color={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "#333333"
                            }
                          >
                            {item.node.label}
                          </Description>
                          <GroupPlusIcon
                            display={currentGroup === 3 ? "none" : "block"}
                          >
                            {" "}
                            +{" "}
                          </GroupPlusIcon>
                          <GroupMinusIcon
                            display={currentGroup === 3 ? "block" : "none"}
                          >
                            {" "}
                            -{" "}
                          </GroupMinusIcon>
                        </OneYear>
                      )
                    }
                    return <></>
                  })}
                </YearsGroup>
                <YearsGroup>
                  {timelineData.edges.map((item, i) => {
                    if (i >= group * 3) {
                      return (
                        <OneYear
                          bg={
                            item.node.year === currentTimeline.year
                              ? "#0059B2"
                              : "white"
                          }
                          zIndex={
                            item.node.year === currentTimeline.year
                              ? "50"
                              : `${4 * group - i}`
                          }
                          left={`${(i - 3 * group) * 4}%`}
                          width={`${100 - (i - 3 * group) * 8}%`}
                          top={
                            currentGroup === 4
                              ? `-${(i - group * 3) * 30}px`
                              : `-${(i - group * 3) * 15}px`
                          }
                          fSize={
                            currentGroup === 4 &&
                            item.node.year !== currentTimeline.year
                              ? "20px"
                              : "24px"
                          }
                          mTop={
                            currentGroup === 4 &&
                            item.node.year !== currentTimeline.year
                              ? "-20px"
                              : "0"
                          }
                          display={
                            currentGroup === 4 &&
                            item.node.year !== currentTimeline.year
                              ? "inline-block"
                              : "none"
                          }
                          onClick={() => this.onClickTimeline(item, 4)}
                        >
                          <Year
                            color={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "#000000"
                            }
                          >
                            {item.node.year}
                            <ArrowIcon
                              fill={
                                item.node.year === currentTimeline.year
                                  ? "white"
                                  : "#FAB702"
                              }
                            />
                          </Year>
                          <Description
                            opacity={
                              item.node.year === currentTimeline.year
                                ? "1"
                                : "0.5"
                            }
                            color={
                              item.node.year === currentTimeline.year
                                ? "white"
                                : "#333333"
                            }
                          >
                            {item.node.label}
                          </Description>
                          <GroupPlusIcon
                            display={currentGroup === 4 ? "none" : "block"}
                          >
                            {" "}
                            +{" "}
                          </GroupPlusIcon>
                          <GroupMinusIcon
                            display={currentGroup === 4 ? "block" : "none"}
                          >
                            {" "}
                            -{" "}
                          </GroupMinusIcon>
                        </OneYear>
                      )
                    }
                    return <></>
                  })}
                </YearsGroup>
              </Slider>
            </SliderWrap>
          </div>
        </HeroWrap>
        <ServiceContact data={ContactDat} />
        <AddressCTA data={AddressData} />
      </Layout>
    )
  }
}

export default TimeLinePage

export const pageQuery = graphql`
  query TimeLinePageQuery {
    contentfulTimelinePage {
      metaTitle
      metaDescription
      heroTitle
      sliderTitle
      contactForm {
        title
        secondTitle
        image {
          gatsbyImageData(quality: 100)
        }
      }
      addressCta {
        title
        image {
          gatsbyImageData(quality: 100)
        }
        linkText
        label
        features {
          address
          phone
          email
        }
      }
    }
    allContentfulTimeline(sort: { order: DESC, fields: year }) {
      edges {
        node {
          year
          description {
            childMarkdownRemark {
              html
            }
          }
          label
          image {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
  }
`
